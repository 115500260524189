const TABLE_HEAD = ["Data", "Spektakl", "Gdzie", "Zarezerwuj"];

const TABLE_ROWS = [
  {
    name: "29",
    miesiac: "września",
    projekt: "ramach programu OFF Polska",
    spetakl: "Nosferatu – dziennik zarazy| 17:00",
    date: "Teatr Łąstek Supraśl",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "29",
    miesiac: "września",
    projekt: "ramach programu OFF Polska",
    spetakl: "Nosferatu – dziennik zarazy| 19:00",
    date: "Teatr Łąstek Supraśl",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "5",
    miesiac: "października",
    projekt: "ramach programu OFF Polska",
    spetakl: "Nosferatu – dziennik zarazy| 18:00",
    date: "Czeremcha plac przy Gminnym Ośrodku Kultury, ul. 1 Maja 77",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "11",
    miesiac: "października",
    projekt: "ramach programu OFF Polska",
    spetakl: "Nosferatu – dziennik zarazy| 17:00",
    date: "plac przy Gminnym Ośrodku Kultury, Krypno Wielkie 9",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "12",
    miesiac: "października",
    projekt: "ramach programu OFF Polska",
    spetakl: "Nosferatu – dziennik zarazy| 18:00",
    date: "plac przy Gminnym Ośrodku Kultury w Goniądzu, ul. Stary Rynek 23,",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "13",
    miesiac: "października",
    projekt: "ramach programu OFF Polska",
    spetakl: "Nosferatu – dziennik zarazy| 18:00",
    date: "Szudziałowo plac przy Gminnym Ośrodku Animacji Kultury i Rekreacji, ul. Szkolna 2",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "25",
    miesiac: "listopada",
    projekt: "",
    spetakl: "MATECZNIK | 18:00",
    date: "InstytutTeatralny w Warszawie ul. Jazdów 1",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },

{
  name: "28",
  miesiac: "grudnia",
  projekt: "",
  spetakl: "DOM POSTERUNKOWA 1 | 18:00",
  date: "Supraśl ul. Posterunkowa 1/4",
  link: "",
  tekst: "Teatr Łątek - 40zł",
},
{
  name: "29",
  miesiac: "grudnia",
  projekt: "",
  spetakl: "DOM POSTERUNKOWA 1 | 18:00",
  date: "Supraśl ul. Posterunkowa 1/4",
  link: "",
  tekst: "Teatr Łątek - 40zł",
},

];

export { TABLE_HEAD, TABLE_ROWS };
